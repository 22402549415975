export const LOGIN = "login"//"oauth/token";
export const VERIFY = "users/verify/";
export const FIND_TOKEN = "forgot-password/";
export const RESET_PASSWORD = "reset-password";
export const PASSWORD_FORGOT = "forgot-password";
export const LOGOUT = "logout";
export const GET_USER_INFOS = "login";
export const STAFF = "staff"
export const DASHBOARD = "dashboard"
export const NOTIFICATIONS = "notifications"
