import { getWithoutParams} from "@/services/resources";

export const  state = {
    todos: [],

    };
export const mutations = {


    };
export const actions = {
            changeQuestionStatus(_,id){
            return new Promise((resolve,reject)=>{
                getWithoutParams( `read-question/${id}`).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
    };
export const getters =  {

};

