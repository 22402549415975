import {store,} from "@/services/resources";

export const  state = {
    todos: [],

    };
export const mutations = {


    };
export const actions = {
        changeStatus(_,data){
            return new Promise((resolve,reject)=>{
                store('school-periods-status',data).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
    };
export const getters =  {

    };

