import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/presentation/create/:target',
        name: 'presentations.create',
        meta: {
             middleware: [
        auth,
      ],
            title: "Presentation", authRequired: true,
        },
        component: () => import('../../views/ghm/presentations/create.vue')
    },
    {
        path: '/presentations/',
        children :[
            {
                path : 'admin',
                name : 'presentations.admin',
                component: () => import('../../views/ghm/presentations/admin-presentation/index.vue')
            },
            {
                path : 'pp',
                name : 'presentations.pp',
                component: () => import('../../views/ghm/presentations/pp-presentation/index.vue')
            },
            {
                path : 'parents',
                name : 'presentations.parents',
                component: () => import('../../views/ghm/presentations/parents-presentation/index.vue')
            },
        ] ,
        meta: {
             middleware: [
                 auth,
             ],
            title: "Presentation", authRequired: true,
        },
    },
    {
        path: '/presentations/admin',
        name: 'presentations.admin',
        meta: {
             middleware: [
        auth,
      ],
            title: "Presentation", authRequired: true,
        },
        component: () => import('../../views/ghm/presentations/admin-presentation/index.vue')
    },
    {
        path: '/presentations/:target/:id',
        name: 'presentations.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Mise à jour Presentation", authRequired: true,
        },
        component: () => import('../../views/ghm/presentations/create.vue')
    },
    {
        path: '/presentations/:id',
        name: 'presentations.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails Presentation", authRequired: true,
        },
        component: () => import('../../views/ghm/presentations/show.vue')
    },
    {
        path: '/school-trainings',
        name: 'school-trainings.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/school-trainings/index.vue')
    },
    {
        path: '/school-trainings/create',
        name: 'school-trainings.create',
        meta: {
             middleware: [
        auth,
      ],
            title: "Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/school-trainings/create.vue')
    },
    {
        path: '/school-trainings/:id/edit',
        name: 'school-trainings.edit',
        meta: {
             middleware: [
        auth,
      ],
            title: "Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/school-trainings/create.vue')
    },
    {
        path: '/school-trainings/:id',
        name: 'school-trainings.show',
        meta: {
             middleware: [
        auth,
      ],
            title: "Détails Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/school-trainings/show.vue')
    },
    {
        path: '/meetings',
        name: 'meetings.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Réunion mensuelle", authRequired: true,
        },
        component: () => import('../../views/ghm/meetings/index.vue')
    },
    {
        path: '/meetings/create',
        name: 'meetings.create',
        meta: {
             middleware: [
        auth,
      ],
            title: "Nouvelle réuinion", authRequired: true,
        },
        component: () => import('../../views/ghm/meetings/create.vue')
    },
    {
        path: '/meetings/:id',
        name: 'meetings.show',
        meta: {
             middleware: [
        auth,
      ],
            title: "Détails Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/meetings/show.vue')
    },
    {
        path: '/meetings/:id/edit',
        name: 'meetings.edit',
        meta: {
             middleware: [
        auth,
      ],
            title: "Mise à jour de Formation", authRequired: true,
        },
        component: () => import('../../views/ghm/meetings/create.vue')
    },
    //plannings
    {
        path: '/plannings',
        name: 'plannings.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Plan de suivis", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/index.vue')
    },
    {
        path: '/plannings/create',
        name: 'plannings.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Plan de suivi", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/create.vue')
    },
    {
        path: '/plannings/:id/edit',
        name: 'plannings.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Plan de suivi", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/create.vue')
    },

    {
        path: '/animator-evaluations/create',
        name: 'animator-evaluations.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails Formations", authRequired: true,
        },
        component: () => import('../../views/ghm/animator-evaluations/create.vue')
    },
    {
        path: '/animator-evaluations/:id/edit',
        name: 'animator-evaluations.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Évaluation", authRequired: true,
        },
        component: () => import('../../views/ghm/animator-evaluations/create.vue')
    },
    {
        path: '/animator-evaluations',
        name: 'animator-evaluations.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Évaluation des animatrices", authRequired: true,
        },
        component: () => import('../../views/ghm/animator-evaluations/index.vue')
    },
    {
        path: '/animator-evaluations/:id',
        name: 'animator-evaluations.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails Évaluation", authRequired: true,
        },
        component: () => import('../../views/ghm/animator-evaluations/show.vue')
    },
    {
        path: '/reports/:type',
        name: 'reports.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rapport ", authRequired: true,
        },
        component: () => import('../../views/ghm/reports/index.vue')
    },
    {
        path: '/ghm/report',
        name: 'ghm.reports.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rapport mensuel", authRequired: true,
        },
        component: () => import('../../views/ghm/monthly-reports/index.vue')
    },
    {
        path: '/ghm/indicators',
        name: 'ghm.indicators.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Indicateurs", authRequired: true,
        },
        component: () => import('../../views/ghm/indicators/index.vue')
    },
    {
        path: '/etat-latrines',
        name: 'etat-latrines.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Etat des latrines", authRequired: true,
        },
        component: () => import('../../views/ghm/etat-latrines/index.vue')
    },
    {
        path: '/kit-stocks',
        name: 'kit-stocks.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Stock des kits", authRequired: true,
        },
        component: () => import('../../views/ghm/kit-stocks/index.vue')
    },
    {
        path: '/kit-stocks/:id',
        name: 'kit-stocks.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Stock des kits", authRequired: true,
        },
        component: () => import('../../views/ghm/kit-stocks/show.vue')
    },
    {
        path: '/etat-latrines/:id',
        name: 'etat-latrines.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Etat des toilettes", authRequired: true,
        },
        component: () => import('../../views/ghm/etat-latrines/show.vue')
    },
    {
        path: '/administration-members',
        name: 'administration-members.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Membres des administrations", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/administration-members/index.vue')
    },
    {
        path: '/stakeholders',
        name: 'stakeholders.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Parties prenantes", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/stakeholders/index.vue')
    },
    {
        path: '/elections',
        name: 'elections.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Élection des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/elections/index.vue')
    },
    {
        path: '/elections/create',
        name: 'elections.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Élection des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/elections/create.vue')
    },
    {
        path: '/elections/:id/edit',
        name: 'elections.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Élection des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/elections/create.vue')
    },
    {
        path: '/elections/:id',
        name: 'elections.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Élection des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/elections/show.vue')
    },
    {
        path: '/questions',
        name: 'questions.index',
        meta: {
            middleware: [
                auth,
            ],
            status : 'asked',
            title: "Questions aux PR", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/index.vue')
    },
    {
        path: '/feedback-received',
        name: 'feedback.received',
        meta: {
            middleware: [
                auth,
            ],
            status : 'received',
            title: "Feedback à lire", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/index.vue')
    },
    {
        path: '/feedback-read',
        name: 'feedback.read',
        meta: {
            middleware: [
                auth,
            ],
            status : 'read',
            title: "Feedback lu", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/index.vue')
    },
    {
        path: '/questions/create',
        name: 'questions.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Questions aux PR", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/create.vue')
    },
    {
        path: '/questions/:id/edit',
        name: 'questions.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Questions aux PR", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/create.vue')
    },
    {
        path: '/questions/:id',
        name: 'questions.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Questions aux PR", authRequired: true,
        },
        component: () => import('../../views/ghm/questions/show.vue')
    },
    {
        path: '/pr-trainings',
        name: 'pr-trainings.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Formations des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/pr-trainings/index.vue')
    },
    {
        path: '/pr-trainings/:id',
        name: 'pr-trainings.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Formations des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/pr-trainings/show.vue')
    },
    {
        path: '/pr-trainings/create',
        name: 'pr-trainings.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Nouvelle formation des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/pr-trainings/create.vue')
    },
    {
        path: '/pr-trainings/:id/edit',
        name: 'pr-trainings.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Modifier formation des PR", authRequired: true,
        },
        component: () => import('../../views/ghm/pr-trainings/create.vue')
    },
    {
        path: '/staff-trainings',
        name: 'staff-trainings.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Formations du personnel", authRequired: true,
        },
        component: () => import('../../views/ghm/staff-trainings/index.vue')
    },
    {
        path: '/staff-trainings/:id',
        name: 'staff-trainings.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Formations du personnel", authRequired: true,
        },
        component: () => import('../../views/ghm/staff-trainings/show.vue')
    },
    {
        path: '/staff-trainings/create',
        name: 'staff-trainings.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Nouvelle formation du personnel", authRequired: true,
        },
        component: () => import('../../views/ghm/staff-trainings/create.vue')
    },
    {
        path: '/staff-trainings/:id/edit',
        name: 'staff-trainings.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Modifier formation du personnel", authRequired: true,
        },
        component: () => import('../../views/ghm/staff-trainings/create.vue')
    },

    {
        path: '/action-plan-meetings',
        name: 'action-plan-meetings.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Réunion d'élaboration du plan d’actions", authRequired: true,
        },
        component: () => import('../../views/ghm/action-plan-meetings/index.vue')
    },
    {
        path: '/action-plan-meetings/:id',
        name: 'action-plan-meetings.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Réunion d'élaboration du plan d’actions", authRequired: true,
        },
        component: () => import('../../views/ghm/action-plan-meetings/show.vue')
    },
    {
        path: '/action-plan-meetings/create',
        name: 'action-plan-meetings.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Nouvelle Réunion", authRequired: true,
        },
        component: () => import('../../views/ghm/action-plan-meetings/create.vue')
    },
    {
        path: '/action-plan-meetings/:id/edit',
        name: 'action-plan-meetings.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Modifier une réunion", authRequired: true,
        },
        component: () => import('../../views/ghm/action-plan-meetings/create.vue')
    },
    {
        path: '/actions-plans/elaborate',
        name: 'actions-plans.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Établissement", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/index.vue')
    },
    {
        path: '/actions-plans/elaborate/:id',
        name: 'actions-plans.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Plans d'actions", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/show.vue')
    },
    {
        path: '/actions-plans/monitoring',
        name: 'actions-plans.monitoring',
        meta: {
            middleware: [
                auth,
            ],
            title: "Suivis", authRequired: true,
        },
        component: () => import('../../views/ghm/plannings/suivis.vue')
    },
]