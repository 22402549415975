import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/campaigns',
        name: 'campaigns.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Gestion des campagnes", authRequired: true,
        },
        component: () => import('../../views/ants/campaigns/index.vue')
    },
    {
        path: '/campaigns/:id',
        name: 'campaigns.show',
        meta: {
             middleware: [
        auth,
      ],
            title: "Détails campagnes", authRequired: true,
        },
        component: () => import('../../views/ants/campaigns/show.vue')
    },
    {
        path: '/sites',
        name: 'sites.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Sites", authRequired: true,
        },
        component: () => import('../../views/ants/sites/index.vue')
    },
    {
        path: '/sites/:id',
        name: 'sites.show',
        meta: {
             middleware: [
        auth,
      ],
            title: "Détails du site", authRequired: true,
        },
        component: () => import('../../views/ants/sites/show.vue')
    },
    {
        path: '/site-visits/:site_id/:id?',
        name: 'site-visits.show',
        meta: {
             middleware: [
        auth,
      ],
            title: "Détails du site", authRequired: true,
        },
        component: () => import('../../views/ants/sites/donations.vue')
    },
    {
        path: '/givers',
        name: 'givers.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Donneurs", authRequired: true,
        },
        component: () => import('../../views/ants/givers/index.vue')
    },
    {
        path: '/givers/create',
        name: 'givers.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Nouveau donneur", authRequired: true,
        },
        component: () => import('../../views/ants/givers/create.vue')
    },
    {
        path: '/givers/:id/edit',
        name: 'givers.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Mise à jour de donneur", authRequired: true,
        },
        component: () => import('../../views/ants/givers/create.vue')
    },
    {
        path: '/givers/:id',
        name: 'givers.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails donneur", authRequired: true,
        },
        component: () => import('../../views/ants/givers/show.vue')
    },
    {
        path: '/donations',
        name: 'donations.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Don de sangs", authRequired: true,
        },
        component: () => import('../../views/ants/donations/index.vue')
    },
    {
        path: '/ants/report',
        name: 'ants.reports.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Rapport", authRequired: true,
        },
        component: () => import('../../views/ants/reports/index.vue')
    },

]