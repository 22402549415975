import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import VueClipboard from 'vue-clipboard2'

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';
import '@/assets/scss/mermaid.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import flatPickr from "vue-flatpickr-component";
import excel from 'vue-excel-export'
import {AppMixins} from "@/mixins/AppMixins";
import { CountTo } from "vue3-count-to";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});
const options = {
    confirmButtonColor: '#006AB3',
    cancelButtonColor: '#2E2D2C',
};

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .component('flat-pickr', flatPickr)
    .use(Maska)
    .use(VueClipboard)
    .use(VueSweetalert2,options)
    .use(vClickOutside)
    .use(excel)
    .use(VueViewer)
    .component('v-select', vSelect)
    .component('count-to', CountTo)
    .mixin( {...AppMixins})
    .mount('#app');