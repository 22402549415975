
export const  state = {
    todos: [],

};
export const mutations = {


};
export const actions = {
    getResult(results,agentId){
        return results.find((r) => r.agent_id === agentId)
    },
    selectCandidate(result){
        this.candidate = result.pr
        this.selectModal = true;
        this.item.agent_id = result.pr.id
        this.item.type = 'pr'
        this.item.election_result_id = result.id
    },
    changeResultStatus(result,status){
        this.candidate = result.pr
        this.item.election_result_id = result.id
        this.item.status = status
        this.changeStatusModal = true;

    },
    submitStatus(){
        this.submitting = true;
        this.$store.dispatch("crud/store", {data: this.item, resource: 'change-election-status'}).then(() => {
            this.init();
            this.$swal('Statut', "Statut de l'élection mis à jour avec succès", 'success')
            if (this.$refs?.form)
            {
                this.$refs.form.reset()
            }

        }).catch((error) => {
            switch (error.response?.status) {
                case 500 :
                    this.$swal('Enregistrement', 'Oups! une erreur est survenue, veuillez ressayer', 'error')
                    break;
                case 422:
                    this.validationErrors = error.response.data.errors
                    break;
                default :
                    console.log({error})
                    break;
            }
        }).finally(() => {
            this.submitting = false;
        });
    }
};
export const getters =  {

};

