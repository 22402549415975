import moment from "moment/moment";

export const AppMixins =  {
    methods: {
        async downloadDataUrlFromJavascript(filename, url) {
            // Construct the 'a' element
            var link = document.createElement("a");
            link.download = filename;
            link.target = "_blank";

            // Construct the URI
            link.href = url;
            document.body.appendChild(link);
            link.click();

            // Cleanup the DOM
            document.body.removeChild(link);
            //delete link;

            /*const data = await fetch(url)
            const blob = await data.blob()
            const objectUrl = URL.createObjectURL(blob)

            const link = document.createElement('a')

            link.setAttribute('href', objectUrl)
            link.setAttribute('download', filename)
            link.style.display = 'none'

            document.body.appendChild(link)

            link.click()

            document.body.removeChild(link)*/
        },
        resolve(path, obj) {
            return path.split('.').reduce(function(prev, curr) {
                return prev ? prev[curr] : null
            }, obj || self)
        },
        initSelect2()
        {
            // eslint-disable-next-line no-undef
            $(document).ready(function() {
                // eslint-disable-next-line no-undef
                $('.select-2').select2();
            });
        },
        projectIsSelected()
        {
            return localStorage.getItem("project")
        },
        toast(type,message)
        {
            let vueBOx=this,
                Toast= this.$swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', vueBOx.$swal.stopTimer)
                        toast.addEventListener('mouseleave', vueBOx.$swal.resumeTimer)
                    }
                });
            Toast.fire({
                icon: type,
                title: message
            })
        },
        momentJs(data)
        {
            var date = new Date((data));
            return moment(date).startOf('hour').fromNow();
        },
        limitText: function (text,length)
        {
            if(text) {
                let mytext = text.slice(0, length);
                (text.length > 12) ? mytext = mytext + ' ...' : '';
                return mytext;
            }else{
                return '...';
            }
        },
        userHasPermission(permission)
        {
            let selectedProject = this.$store.getters["auth/user"] ? this.$store.getters["auth/user"].all_projects.find((project) => project.code === localStorage.getItem('project')) : {}
            let projectPermissions = selectedProject.permissions
            if (projectPermissions)
            {
                return this.$store.getters["auth/user"].permissions.includes(permission.toString()) && projectPermissions.includes(permission.toString())
            }

            return false;
        },
        getConnected()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.identity;
        },
        getUserFullRole()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.roles[0]
        },
        getUserRole()
        {
            if (this.$store.getters["auth/user"])
                return this.$store.getters["auth/user"].data.roles[0].name
        },

    }
}