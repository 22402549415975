import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/kit-types',
        name: 'kit-types.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Serviettes par kit", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/kit-types/index.vue')
    },
    {
        path: '/ghm-periodicities',
        name: 'ghm-periodicities.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Périodicités", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/ghm-periodicities/index.vue')
    },
    {
        path: '/languages',
        name: 'languages.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Langues", authRequired: true,
        },
        component: () => import('../../views/configs/common/languages/index.vue')
    },
    {
        path: '/identifier-types',
        name: 'identifier-types.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Type Identificateur", authRequired: true,
        },
        component: () => import('../../views/configs/fo/identifier-types/index.vue')
    },
    {
        path: '/identifiers',
        name: 'identifiers.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Identificateur", authRequired: true,
        },
        component: () => import('../../views/configs/fo/identifiers/index.vue')
    },
    {
        path: '/departements',
        name: 'departements.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Départements", authRequired: true,
        },
        component: () => import('../../views/configs/common/departements/index.vue')
    },
    {
        path: '/communes',
        name: 'communes.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Communes", authRequired: true,
        },
        component: () => import('../../views/configs/common/communes/index.vue')
    },
    {
        path: '/villages',
        name: 'villages.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Villages", authRequired: true,
        },
        component: () => import('../../views/configs/common/villages/index.vue')
    },
    {
        path: '/arrondissements',
        name: 'arrondissements.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Arrondissement", authRequired: true,
        },
        component: () => import('../../views/configs/common/arrondissements/index.vue')
    },
    {
        path: '/trainings',
        name: 'trainings.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Formation", authRequired: true,
        },
        component: () => import('../../views/configs/fo/trainings/index.vue')
    },
    {
        path: '/reception-centers',
        name: 'reception-centers.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Centre d'Tableau de bord", authRequired: true,
        },
        component: () => import('../../views/configs/fo/reception-centers/index.vue')
    },
    {
        path: '/symptoms',
        name: 'symptoms.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Symptôme", authRequired: true,
        },
        component: () => import('../../views/configs/fo/symptoms/index.vue')
    },
    {
        path: '/professions',
        name: 'professions.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Profession", authRequired: true,
        },
        component: () => import('../../views/configs/fo/professions/index.vue')
    },
    {
        path: '/tests',
        name: 'tests.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Tests", authRequired: true,
        },
        component: () => import('../../views/configs/fo/tests/index.vue')
    },
    {
        path: '/project-categories',
        name: 'project-categories.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Catégories des projets", authRequired: true,
        },
        component: () => import('../../views/configs/fo/project-categories/index.vue')
    },
    {
        path: '/hospitals',
        name: 'hospitals.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Hôpitaux partenaire", authRequired: true,
        },
        component: () => import('../../views/configs/fo/hospitals/index.vue')
    },
    {
        path: '/agrs',
        name: 'agrs.index',
        meta: {
             middleware: [
        auth,
      ],
            title: "Activités Génératrice de Revenus", authRequired: true,
        },
        component: () => import('../../views/configs/fo/agrs/index.vue')
    },
    {
        path: '/missions',
        name: 'missions.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Missions", authRequired: true,
        },
        component: () => import('../../views/fo/missions/index.vue')
    },

    //GHM
    {
        path: '/exercices',
        name: 'school-periods.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Exercices", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/school-periods/index.vue')
    },
    {
        path: '/schools',
        name: 'schools.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Établissement", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/schools/index.vue')
    },
    {
        path: '/schools/:id/:election?',
        name: 'schools.show',
        meta: {
            middleware: [
                auth,
            ],
            title: "Détails Établissement", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/schools/show.vue')
    },
    {
        path: '/school-classes',
        name: 'school-classes.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Classes", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/school-classes/index.vue')
    },
    {
        path: '/students/:id',
        name: 'students.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Elèves", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/students/index.vue')
    },
    {
        path: '/thematics',
        name: 'thematics.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Thématiques", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/thematics/index.vue')
    },
    {
        path: '/agent-statuses',
        name: 'agent-statuses.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Statut d'agent", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/agent-statuses/index.vue')
    },
    {
        path: '/action-statuses',
        name: 'action-statuses.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Statuts d'un plan d'actions", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/action-statuses/index.vue')
    },
    {
        path: '/responsable-departements',
        name: 'responsable-departement.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Responsable département", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/departement-resposibles/index.vue')
    },
    {
        path: '/animators',
        name: 'animators.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Animatrices", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/animators/index.vue')
    },
    {
        path: '/resource-people',
        name: 'resource-people.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Personne ressource", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/resource-people/index.vue')
    },
    {
        path: '/config-resources',
        name: 'config-resources.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Personne ressource", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/config-resources/index.vue')
    },
    {
        path: '/training-types',
        name: 'training-types.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Type de formation", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/training-types/index.vue')
    },
    {
        path: '/human-resources/:type?',
        name: 'human-resources.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Resources humaines", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/human-resources/index.vue')
    },
    {
        path: '/graphs',
        name: 'graphs.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Graphes", authRequired: true,
        },
        component: () => import('../../views/configs/ghm/graphs/index.vue')
    },
    {
        path: '/roles',
        name: 'roles.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/index.vue')
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },
    {
        path: '/roles/create',
        name: 'roles.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },

    //ANTS
    {
        path: '/site-types',
        name: 'site-types.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Type de sites", authRequired: true,
        },
        component: () => import('../../views/configs/ants/site-types/index.vue')
    },
    {
        path: '/climates',
        name: 'climates.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Climats", authRequired: true,
        },
        component: () => import('../../views/configs/ants/climates/index.vue')
    },
    {
        path: '/weather',
        name: 'weather.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Météos", authRequired: true,
        },
        component: () => import('../../views/configs/ants/weather/index.vue')
    },
]