/*eslint-disable*/
export default function auth ({store, next}){
    let token = localStorage.getItem('token');
    let project = localStorage.getItem('project');
    if(!token){
        return next({
            name: 'login'
        })
    }else{
        if (!project){
            return next({
                name: 'onboard'
            })
        }else{
            return next();
        }
    }
}
