import {download,} from "@/services/resources";

export const actions = {
    download(_,data){
            return new Promise((resolve,reject)=>{
                download(data.resource).then((response)=>{
                    resolve(response)
                }).catch((error)=>{
                    reject(error)
                })
            })
        },
};


