<template>
 <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  components: {

  },
  mounted() {

  },


}
</script>
<style>
 :root{
   --vs-font-size: 0.8125rem !important;
   --vs-line-height: 1.5;
 }
 .vs__search, .vs__search :focus{
   padding: 4px !important;
   color: #989797 !important;
 }

</style>