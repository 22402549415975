import axios from "axios";
import {store} from "@/services/resources";

export const state = {
    todos: [],
};

export const getters = {
    todos: state => state.todos,
};

export const mutations = {
    setTodos(state, newValue) {
        state.todos = newValue
    },
};

export const actions = {
    fetchTodos({ commit }) {
        axios.get('https://jsonplaceholder.typicode.com/todos').then(res => {
            commit('setTodos', res.data)
        })
    },
    changeStatus(_,data){
        return new Promise((resolve,reject)=>{
            store('school-periods-status',data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
};
