import {
    findToken,
    getUserData,
    login, notifications, resetPassword, sendReset, verify
} from '@/services/auth'
import client from "@/state/axios/client";

export const state = {
    userData:undefined,
    notifications:undefined,
    unreadNotifications:undefined,
};

export const getters = {
    user(state) {
        return state.userData;
    }
};
export const mutations =  {
    SET_USER_DATA(state,payload){
        state.userData = {...payload}
    },
    SET_NOTIFICATIONS(state,payload){
        state.notifications = payload
    },
    SET_UNREAD_NOTIFICATIONS(state,payload){
        state.unreadNotifications = payload
    },
};
export const actions = {
    // eslint-disable-next-line no-unused-vars
    login({commit},data){
        return new Promise((resolve,reject)=>{
            data.append('grant_type',"password")
            data.append('client_id',process.env.VUE_APP_CLIENT_ID)
            data.append('client_secret',process.env.VUE_APP_CLIENT_SECRET)
            login(data).then((response)=>{
                localStorage.setItem("token",response.data.access_token);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    resetPassword(_,data){
        return new Promise((resolve,reject)=>{
            resetPassword(data).then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    verify(data){
        return new Promise((resolve,reject)=>{
            verify(data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    find(_,data){
        return new Promise((resolve,reject)=>{
            findToken(data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    sendResetMail(_,data){
        console.log({data})
        return new Promise((resolve,reject)=>{
            sendReset(data).then((response)=>{
                resolve(response.data)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    getUserData({commit}){
        return new Promise((resolve,reject)=>{
            getUserData().then((response)=>{
                commit('SET_USER_DATA',response.data)
                resolve(response.data)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    getUnreadNotifications({commit}){
        return new Promise((resolve,reject)=>{
            notifications('unread').then((response)=>{
                commit('SET_UNREAD_NOTIFICATIONS',response.data)
                resolve(response.data)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    getAllNotifications({commit}){
        return new Promise((resolve,reject)=>{
            notifications().then((response)=>{
                commit('SET_NOTIFICATIONS',response.data)
                resolve(response.data)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    deleteAllNotifications(){
        return new Promise((resolve,reject)=>{
            notifications('delete').then((response)=>{
                resolve(response.data)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    markAllNotificationsAsRead(){
        return new Promise((resolve,reject)=>{
            notifications('read-all').then((response)=>{
                resolve(response.data)
            }).catch((err)=>{
                reject(err)
            })
        })
    },
    logout() {
        let url = 'logout';
        return new Promise((resolve, reject) => {
            client({
                method: 'get',
                url: url
            }).then(() => {
                localStorage.clear();
                location.reload();
            }).catch((error) => {
                reject(error)
            })
        })
    },
};
